import React from "react";
import YogaClassImg from "../../images/yoga-classes.jpg"
import YogaRetreatImg from "../../images/yoga-retreat-1.jpg"
import EventImg from "../../images/event.png"
import * as CardStyles from "./card.module.css"

export default function Card({ imgKey, title, content, link, type, footer }) {
    const imgMap = {
        "yogaClass": YogaClassImg,
        "yogaRetreat": YogaRetreatImg,
        "yogaEvent": EventImg
    }
    return (
        <div className="card">
            <div className="card-image">
                {type === 'Event' ?
                    <figure className="image is-6by7">
                        {/* <img src={imgMap[imgKey]} alt="yoga training and retreat images" /> */}
                        {/* <div className={`${CardStyles.dateBadge}`}>
                            <p className={`has-text-white has-text-weight-bold ${CardStyles.dateTextBadge}`}>Nov 26</p>
                        </div> */}  
                    </figure>
                    : <figure className="image is-6by7">
                        <img src={imgMap[imgKey]} alt="yoga training and retreat images" />
                    </figure>
                }

            </div>
            <div className="card-content has-text-centered">
                <p className="title is-4 is-family-secondary">{title}</p>
                <div className="content">
                    {content}
                </div>
                {!footer?
                (<a href={link} className="is-size-7 has-text-weight-semibold">LEARN MORE</a>)
                :(<div className="has-text-weight-semibold is-italic">Mondays - 6:30pm to 7:30pm - July 4<sup>th</sup> to August 8<sup>th</sup><p>Contact Heidi at 519.755.1447 or heidi@yogawithheidi.ca</p> </div>)
                }
                
            </div>
        </div>
    )
}