import React from "react";
import "./style.scss"
import Layout from "../components/layout";
import Hero from "../components/hero/hero";
import Row from "../components/row/row";
import Card from "../components/card/card";
import Button from "../components/button/button";
import { StaticImage } from "gatsby-plugin-image";
import * as HomeStyle from './home.module.css'

export default function Index() {
    return (
        <Layout pageTitle={'Home'}>
            <Hero titleText={`Yoga and Retreats`} subText={`SIGN ME UP!`}>
                <StaticImage
                    src={`../images/home.png`}
                    alt="A picture of Heidi"
                    placeholder="blurred"
                    layout="fullWidth"
                    objectFit="cover"
                    quality={100}
                />
                <div className="darken-image"></div>
            </Hero>
            <div className="block-3x"></div>
            <Row >
                <div className="column is-2"></div>
                <StaticImage
                    src={`../images/yoga-teacher.jpg`}
                    alt="A picture of Heidi teaching yoga"
                    placeholder="blurred"
                    quality={75}
                    objectFit="cover"
                    className={`column is-4`}
                />
                <div className="column is-4 pl-6 pr-6">
                    <h2 className="title has-text-black is-5 pb-3">Meet Heidi</h2>
                    <p className="has-text-justified">With over 30 years of teaching experience, Heidi has the ability to teach various levels of Yoga.</p>
                    <p className={`${HomeStyle.centerElement}`}><Button ctaText={'Learn More'} src={`/about`}></Button></p>
                </div>
                <div className="column is-2"></div>
            </Row>
            <Row styles={`mt-2 mb-2 pt-5 pb-5 has-background-primary has-text-white is-family-fancy`}>
                <div className="column is-2"></div>
                <div className="column is-8">
                    <p className="has-text-centered is-size-1">Yoga is not about doing the poses.</p>
                    <p className="has-text-centered is-size-1">It's about undoing what's in the way of the poses.</p> 
                    <p className="has-text-centered is-size-3"></p>  
                    <p className="has-text-centered is-size-3"></p>
                    <p className="has-text-centered is-size-3"></p>
                    <p className="has-text-centered is-size-3">    - Leslie Kaminoff</p>
                </div>
                <div className="column is-2"></div>
            </Row>
            <Row>
                <div className="column is-12 has-text-centered">
                    <p className="title is-3 is-family-secondary pt-6 pb-3">
                        Classes & Retreats
                    </p>
                </div>
            </Row>
            <Row styles={`multi-backgrounds pb-6`}>
                <div className="column is-3"></div>
                <div className="column is-3">
                    <Card
                        imgKey="yogaClass"
                        title={"Classes"}
                        content={"Classes range from Beginner Foundations to Advance levels and incorporate the therapeutic and traditional teachings of Yoga.\n Private and Semi-Private sessions are also available."}
                        link={"/classes"}
                    />
                </div>
                <div className="column is-3">
                    <Card
                        imgKey="yogaRetreat"
                        title={"Retreats"}
                        content={"Whether it’s for a day, a weekend or a week, Heidi’s retreats will help you to align with your highest self and experience a greater sense of physical, emotional and spiritual well being."}
                        link={"/classes"}
                    />
                </div>
                <div className="column is-3"></div>
            </Row>
            <Row>
                <div className="column is-12 has-text-centered">
                    <p className="title is-3 is-family-secondary pt-6 pb-3">
                        Events
                    </p>
                </div>
            </Row>
             <Row>
                <div className="column is-3"></div>
                <div className="column is-6">
                    <Card
                        type="Event"
                        imgKey="yogaEvent"
                        title={"YOGA IN THE PARK"}
                        content={"Join Yoga Instructor Heidi Pfleger this summer for some outdoor fun at Kingsward Park, Paris and help support The Little Free Pantry"}
                        footer={true}
                    />
                </div>
                <div className="column is-3"></div>
                </Row>
               {/*  <div className="column is-2">
                    <Card
                        type="Event"
                        imgKey="yogaEvent"
                        content={"Kundalini Yoga Retreat"}
                        link={"https://google.ca"}
                    />
                </div>
                <div className="column is-2">
                    <Card
                        type="Event"
                        imgKey="yogaEvent"
                        content={"Kundalini Yoga Retreat"}
                        link={"http://google.ca"}
                    />
                </div>
                <div className="column is-3"></div>
            </Row>
            <div className={`mt-5 mb-6 ${HomeStyle.centerElement}`}>
                <Button ctaText={'Learn More'}></Button>
            </div> */}
        </Layout>
    )
}